<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="560px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <el-form-item label="OTA文件：" prop="file">
                  <el-upload
                      ref="uploadRef"
                      :auto-upload="false"
                      :on-change="handleChange"
                      :before-upload="beforeUpload"
                      :file-list="fileList"
                      :limit="1"
                      multiple="false"
                  >
                    <template #trigger>
                      <el-button type="primary">select file</el-button>
                    </template>

                    <template #tip>
                      <div class="el-upload__tip">
                        仅允许上传.bin文件，且大小不超过25MB
                      </div>
                    </template>
                  </el-upload>
                </el-form-item>
                <el-form-item label="文件地址：" prop="otaUrl">
                  <el-input v-model="formData.otaUrl"></el-input>
                </el-form-item>
                <el-form-item label="md5：" prop="md5">
                    <el-input v-model="formData.md5"></el-input>
                </el-form-item>
                <el-form-item label="ota文件版本：" prop="version">
                    <el-input v-model="formData.version"></el-input>
                </el-form-item>
                <el-form-item label="版本备注：" prop="description">
                  <el-input type="textarea" v-model="formData.description"></el-input>
                </el-form-item>

            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import {OTAAdd,OTAEdit} from "@/api/api";
    import LyDialog from "../../../components/dialog/dialog";

    export default {
        name: "addOTA",
        components: {LyDialog},
        emits: ['refreshData'],
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                formData:{
                    file:null,
                    otaUrl:'',
                    md5:'',
                    version:'',
                    description:'',
                    is_latest:false,
                    is_default:false,
                },
                fileList: [],
                rules:{
                    file: [
                      { required: true, message: '请上传文件', trigger: 'change' },
                    ],
                    md5: [
                        {required: true, message: '请输入md5',trigger: 'blur'}
                    ],
                    version: [
                        {required: true, message: '请输入版本号',trigger: 'blur'}
                    ],
                    description: [
                        {required: false, message: '请输入版本备注信息',trigger: 'blur'}
                    ],

                },
                rolelist:[],
                options:[],
            }
        },
        methods:{
            handleChange(file, fileList) {
              this.fileList = fileList;
              if (fileList.length > 0) {
                this.formData.file = file.raw;
              } else {
                this.formData.file = null;
              }
            },
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.$emit('refreshData')
            },
            addOtaFn(item,flag) {
                this.loadingTitle=flag
                this.dialogVisible=true
                if(item){
                    this.formData = item
                }else{
                   this.formData={
                       file: null,
                       md5:'',
                       version:'',
                       description:'',
                       is_latest:false,
                       is_default:false,
                   }
                }
            },
            beforeUpload(file) {
              console.log("beforeUpload called", file); // 调试输出
              const isBin = file.name.endsWith('.bin');
              if (!isBin) {
                this.$message.error('上传的文件必须是.bin格式!');
                return false;
              }
              return true;
            },
            submitData() {
                // console.log(this.formData)
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        const formData = new FormData();
                        formData.append('version', this.formData.version);
                        formData.append('md5', this.formData.md5);
                        formData.append('description', this.formData.description);
                        formData.append('is_latest', this.formData.is_latest);
                        formData.append('is_default', this.formData.is_default);
                        if (this.formData.file) {
                          formData.append('file', this.formData.file);
                        }

                        let param = {
                            ...this.formData,
                            formData
                        }
                        // param.role = param.role?param.role.split(" "):[]
                        if(this.formData.id){
                            OTAEdit(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                    this.$emit('refreshData')
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }else{
                            OTAAdd(formData).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                    this.$emit('refreshData')
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }

                    }
                })
            },
        }
    }
</script>
<style scoped>
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 128px;
      height: 128px;
      line-height: 128px;
      text-align: center;
    }
    .avatar {
      width: 128px;
      height: 128px;
      display: block;
    }
</style>

