<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="560px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <el-form-item label="模型类别：" prop="category">
<!--                  <el-input v-model="formData.category_display"></el-input>-->
                  <el-select v-model="formData.category" placeholder="请选择模型类别">
                    <el-option label="问答" value="0"></el-option>
                    <el-option label="搜索" value="1"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="模型图片：">
                  <el-upload
                      class="avatar-uploader"
                      action=""
                      :show-file-list="false"
                      ref="uploadDefaultImage"
                      :http-request="imgUploadRequest"
                      :on-success="imgUploadSuccess"
                      :before-upload="imgBeforeUpload">
                    <img v-if="formData.avatar" :src="formData.avatar" class="avatar" :onerror="defaultImg">
                    <el-icon v-else class="avatar-uploader-icon" size="medium"><Plus /></el-icon>
                  </el-upload>
                </el-form-item>
                <el-form-item label="模型别名" prop="name">
                  <el-input v-model="formData.nickname"></el-input>
                </el-form-item>
                <el-form-item label="模型名称" prop="name">
                    <el-input v-model="formData.name"></el-input>
                </el-form-item>
                <el-form-item label="模型介绍：" prop="intro">
                    <el-input type="textarea" v-model="formData.intro"></el-input>
                </el-form-item>
                <el-form-item label="算力消耗：" prop="computationpower">
                  <el-input v-model="formData.computationpower"></el-input>
                </el-form-item>

            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import {aiModelAdd,aiModelEdit,platformsettingsUploadPlatformImg} from "@/api/api";
    import LyDialog from "../../../components/dialog/dialog";

    export default {
        name: "addAimodel",
        components: {LyDialog},
        emits: ['refreshData'],
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                defaultImg:'this.src="'+require('../../../assets/img/avatar.jpg')+'"',
                formData:{
                    category:'',
                    category_display:'',
                    nickname:'',
                    avatar:'',
                    name:'',
                    intro:'',
                    computationpower:'',
                },
                fileList: [],
                rules:{
                    category: [
                      { required: true, message: '请填写模型类别', trigger: 'change' },
                    ],
                    nickname: [
                      {required: true, message: '请输入模型别名',trigger: 'blur'}
                    ],
                    name: [
                        {required: true, message: '请输入模型名称',trigger: 'blur'}
                    ],
                    intro: [
                        {required: true, message: '请输入模型介绍',trigger: 'blur'}
                    ],
                    computationpower: [
                        {required: true, message: '请输入算力消耗',trigger: 'blur'}
                    ],

                },
                rolelist:[],
                options:[],
            }
        },
        methods:{
            handleChange(file, fileList) {
              this.fileList = fileList;
              if (fileList.length > 0) {
                this.formData.file = file.raw;
              } else {
                this.formData.file = null;
              }
            },
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.$emit('refreshData')
            },
            addOtaFn(item,flag) {
                this.loadingTitle=flag
                this.dialogVisible=true
                if(item){
                    this.formData = item
                }else{
                   this.formData={
                       category:'',
                       category_display:'',
                       name:'',
                       intro:'',
                       computationpower:'',
                   }
                }
            },
            beforeUpload(file) {
              console.log("beforeUpload called", file); // 调试输出
              const isBin = file.name.endsWith('.bin');
              if (!isBin) {
                this.$message.error('上传的文件必须是.bin格式!');
                return false;
              }
              return true;
            },
            submitData() {
                // console.log(this.formData)
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true

                        let param = {
                            ...this.formData,
                        }
                        // param.role = param.role?param.role.split(" "):[]
                        if(this.formData.id){
                            aiModelEdit(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                    this.$emit('refreshData')
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }else{
                            aiModelAdd(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                    this.$emit('refreshData')
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }

                    }
                })
            },
            imgBeforeUpload(file) {
              const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
              if (!isJPG) {
                this.$message.error('图片只能是 JPG/PNG 格式!');
                return false
              }
              return isJPG;
            },
            async imgUploadRequest(param) {
              var vm = this
              let obj= await platformsettingsUploadPlatformImg(param)
              if(obj.code == 2000) {
                let res=''
                if (obj.data.data[0].indexOf("://")>=0){
                  res = obj.data.data[0]

                }else{
                  res = url.split('/api')[0]+obj.data.data[0]
                }
                vm.formData.avatar = res
              } else {
                vm.$message.warning(res.msg)
              }
            },
            imgUploadSuccess() {
              this.$refs.uploadDefaultImage.clearFiles()
            }
          }
      }

</script>
<style scoped>
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 128px;
      height: 128px;
      line-height: 128px;
      text-align: center;
    }
    .avatar {
      width: 128px;
      height: 128px;
      display: block;
    }
</style>

