<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="560px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <el-form-item label="hwuser_id：" prop="hwuser_id">
                  <el-input v-model="formData.hwuser_id"></el-input>
                </el-form-item>
                <el-form-item label="hwId" prop="hwId">
                    <el-input v-model="formData.hwId"></el-input>
                </el-form-item>
                <el-form-item label="ai_model_id：" prop="ai_model_id">
                    <el-input v-model="formData.ai_model_id"></el-input>
                </el-form-item>
                <el-form-item label="prompt：" prop="prompt">
                  <el-input type="textarea" v-model="formData.prompt"></el-input>
                </el-form-item>

            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import {aiHWAdd,aiHWEdit} from "@/api/api";
    import LyDialog from "../../../components/dialog/dialog";

    export default {
        name: "addAimodeluser",
        components: {LyDialog},
        emits: ['refreshData'],
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                formData:{
                    id:'',
                    hwuser_id:'',
                    hwId:'',
                    ai_model_id:'',
                },
                fileList: [],
                rules:{
                    hwuser_id: [
                      { required: false, message: '请填写用户id', trigger: 'blur' },
                    ],
                    hwId: [
                        {required: true, message: '请输入hwId',trigger: 'blur'}
                    ],
                    ai_model_id: [
                        {required: false, message: '请输入模型id',trigger: 'blur'}
                    ],

                },
                rolelist:[],
                options:[],
            }
        },
        methods:{
            handleChange(file, fileList) {
              this.fileList = fileList;
              if (fileList.length > 0) {
                this.formData.file = file.raw;
              } else {
                this.formData.file = null;
              }
            },
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.$emit('refreshData')
            },
            addOtaFn(item,flag) {
                this.loadingTitle=flag
                this.dialogVisible=true
                if(item){
                    this.formData.id = item.id || "";
                    this.formData.hwId = item.hwId;
                    // 使用三元运算符设置 hwuser_id
                    this.formData.hwuser_id = item.hwuser ? item.hwuser.id : "";
                    // 使用三元运算符设置 ai_model_id
                    this.formData.ai_model_id = item.ai_model ? item.ai_model.id : "";
                }else{
                   this.formData={
                       hwuser_id:"",
                       hwId:"",
                       ai_model_id:"",
                   }
                }
            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        let param = {
                            ...this.formData,
                        }
                        let newformData = new FormData();
                        for (let key in param) {
                          if (param.hasOwnProperty(key)) {
                            newformData.append(key, param[key]);
                          }
                        }
                        if (param.id) {
                          newformData.id=param.id;
                        }
                        if(this.formData.id){
                            aiHWEdit(newformData).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                    this.$emit('refreshData')
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }else{
                            aiHWAdd(newformData).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                    this.$emit('refreshData')
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }

                    }
                })
            },
        }
    }
</script>
<style scoped>
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 128px;
      height: 128px;
      line-height: 128px;
      text-align: center;
    }
    .avatar {
      width: 128px;
      height: 128px;
      display: block;
    }
</style>

